import {FirebaseContext} from "../services/Firebase";
import React from "react";
import {Redirect, Route} from "react-router";

export const PrivateRoute = (({component: Component, ...rest}: any) => {
    const firebase = React.useContext(FirebaseContext);
    return <Route
        {...rest}
        render={props => firebase.isLoading ? <></> : (
            firebase.isLoggedIn
                ? <Component {...props} />
                : <Redirect to={"/login"}/>

        )
        }
    />

});
