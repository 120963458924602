import React from "react"
import "./LoadingComponent.css";

export default ({center = true}) => {
    return <div className={center ? "center" : ""}>
        <div className="loadingio-spinner-rolling-xksau4r5igf">
            <div className="ldio-7i0xmj2s4qw">
                <div/>
            </div>
        </div>
    </div>


}
