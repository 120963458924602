import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Firebase, {FirebaseContext} from "./services/Firebase";
import NotificationContext, {NotificationService} from './services/NotificationService';


ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <NotificationContext.Provider value={new NotificationService()}>
            <App/>
        </NotificationContext.Provider>
    </FirebaseContext.Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
