import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {getPlatforms, IonAlert, IonApp, IonRouterOutlet} from '@ionic/react';
import {IonReactHashRouter as IonReactRouter} from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';

import AppContainer from "./pages/app";
import LoginPage from "./pages/Login";
import {FirebaseContext} from "./services/Firebase";
import {observer, useObservable} from "mobx-react-lite";
import LoadingComponent from './components/LoadingComponent';
import NotificationContext, {Notification} from "./services/NotificationService";
import {Plugins} from "@capacitor/core";
import {AppUpdate} from '@ionic-native/app-update';

const {App: IonicApp} = Plugins;
const NotifItem = observer(({notif}: { notif: Notification }) => {
    const onDismiss = React.useCallback(() => {
        notif.open = false;
        // eslint-disable-next-line
    }, []);
    return <IonAlert
        isOpen={notif.open}
        header={notif.title}
        message={notif.content}
        onDidDismiss={onDismiss}
        {...notif.props}
    />
});
const NotificationList = observer(() => {
    const notifications = React.useContext(NotificationContext);
    return <>
        {notifications.notifications.map((notif) =>
            <NotifItem
                key={notif.id}
                notif={notif}
            />
        )}
    </>
});
export const DeepLinkContext = React.createContext({value: ""});
const App: React.FC = observer(() => {
    const firebase = React.useContext(FirebaseContext);
    const deepLink = useObservable({
            value: "",
            setDeepLink: function (value: string) {
                this.value = value;
            }
        }
    );
    let Component: any;
    if (!firebase.isLoading) {
        Component = <Redirect to={firebase.isLoggedIn ? "/app/dispositivos" : "/login"}/>;
    } else {
        Component = <LoadingComponent/>
    }

    React.useEffect(() => {

        IonicApp.addListener("appUrlOpen", (data) => {
            deepLink.setDeepLink(data.url)
        });
        if (getPlatforms().includes("electron")) {
            const {ipcRenderer} = window.require('electron');
            ipcRenderer.on("deeplink", function (event: any, deeplink: string) {
                deepLink.setDeepLink(deeplink);
            })
        }

        if (getPlatforms().includes('android')) {
            AppUpdate.checkAppUpdate(`http://142.44.236.185:3124/app/24c2128f-89a8-41b1-9beb-0ab54ce8f54d/ionic`)
        }
        //adb shell am start -a android.intent.action.VIEW -d com.hidalsoft.hsresidenical://prueba
        // eslint-disable-next-line
    }, []);

    return (
        <IonApp>
            <DeepLinkContext.Provider value={deepLink}>
                <IonReactRouter>
                    <IonRouterOutlet>
                        <Route path={"/app"} component={AppContainer}/>
                        <Route path={"/login"} component={LoginPage}/>
                        <Redirect from='/index.html' to="/"/>
                        <Route path="/" render={() => Component}/>
                    </IonRouterOutlet>
                    <NotificationList/>
                </IonReactRouter>

            </DeepLinkContext.Provider>
        </IonApp>
        );
    }
);
export default App;
