import {IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs} from "@ionic/react";
import {Redirect, Route, useRouteMatch} from "react-router";
import Tab1 from "../tabs/Tab1";
import {square} from "ionicons/icons";
import React from "react";
import {PrivateRoute} from "../../components/PrivateRoute";

export default () => {
    const match = useRouteMatch();
    return <IonTabs>
        <IonRouterOutlet>
            <PrivateRoute path={`${match.path}/dispositivos`} component={Tab1}/>
            <Route path="/" exact={true} render={() => <Redirect to={`${match.path}/dispositivos`}/>}/>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
            <IonTabButton tab="dispositivos" href={`${match.path}/dispositivos`}>
                <IonIcon icon={square}/>
                <IonLabel>Dispositivos</IonLabel>
            </IonTabButton>

        </IonTabBar>
    </IonTabs>;
}
