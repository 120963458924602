import React from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonImg,
    IonPage,
    IonTitle,
    IonToolbar,
    useIonViewDidEnter,
} from '@ionic/react';
import './Tab1.css';
import {Device, FirebaseContext} from "../../services/Firebase";
import {observer, useAsObservableSource} from "mobx-react-lite";
import {logOut, refresh} from "ionicons/icons";
import {reaction} from "mobx";
import {DeepLinkContext} from "../../App";

const Tab1: React.FC = observer(() => {

    const firebase = React.useContext(FirebaseContext);

    const deepLink = useAsObservableSource(React.useContext(DeepLinkContext));
    const [devices, setDevices] = React.useState([] as Device[]);
    const refreshDevices = React.useCallback(() => {
        return firebase?.getUserDevices().then((devices) => {
            setDevices(devices);
            return devices;
        })
    }, [firebase]);
    const onLogout = React.useCallback(() => {
        return firebase.logOut();
    }, [firebase]);
    useIonViewDidEnter(() => {
        try {
            if (!firebase.isLoading)
                refreshDevices().then(r => r);
        } catch (e) {

        }
    }, [refreshDevices, firebase]);
    React.useEffect(() => {
        try {
            refreshDevices().then(r => r);
        } catch (e) {

        }
        // eslint-disable-next-line
    }, [])
    React.useEffect(() =>
        reaction(() => deepLink.value, () => {
            if (deepLink) {
                const onComplete = (devices: Device[]) => {
                    console.log("deepLink", deepLink, devices);
                };
                if (devices.length === 0) {
                    refreshDevices().then(onComplete);
                } else
                    onComplete(devices);
            }
            // eslint-disable-next-line
        }), []);
    const onClickDevice = React.useCallback((device: Device) => {
        firebase.useDevice(device).finally(() => {
        });
    }, [firebase]);
    let content = <></>;
    if (devices.length === 0) {
        content = <div>
            <strong>no se ha encontrado ningun dispositivo configurado</strong>
            <p>Favor contactar a hidalsoft para mas informacion</p>
        </div>;
    } else {
        content = <>
            <IonTitle>Dispositivos</IonTitle>
            <IonImg className={"center"} style={{width: 250}} src="/assets/residencial.jpeg"/>
            {devices.map((device) => {
                console.log("device", device);

                return <IonButton
                    key={device.url}
                    onClick={onClickDevice.bind(null, device)}>{device.nombredispositivo}</IonButton>;
            })}
        </>;
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot={"start"}>
                        <IonButton onClick={refreshDevices}><IonIcon icon={refresh}/></IonButton>
                        <IonButton onClick={onLogout}><IonIcon icon={logOut}/></IonButton>
                    </IonButtons>
                    <IonTitle>Dispositivos</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Dispositivos</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div className="container">
                    {content}
                </div>
            </IonContent>
        </IonPage>
    );
});

export default Tab1;
