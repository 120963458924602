import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import firebase from "firebase";
import React from "react";
import {action, computed, observable} from "mobx";
import axios from "axios";
import axiosRetry, {isNetworkOrIdempotentRequestError} from 'axios-retry';
import ClientJS from "clientjs";

let Axios = axios.create({
    timeout: 5000
})
const API_URL = "http://142.44.236.185:3124"
axiosRetry(Axios, {
    retries: 3,
    shouldResetTimeout: true,
    retryCondition: error => {
        return isNetworkOrIdempotentRequestError(error)
            || error.code === 'ECONNABORTED';
    }
});
const firebaseConfig = {
    apiKey: "AIzaSyBfSUr6N-LJYAJdtSCKYhmcX5np7qJeP-s",
    authDomain: "endlessparadox.firebaseapp.com",
    databaseURL: "https://endlessparadox.firebaseio.com",
    projectId: "firebase-endlessparadox",
    storageBucket: "firebase-endlessparadox.appspot.com",
    messagingSenderId: "145302576085",
    appId: "1:145302576085:web:dfc38156eb3deca5b7fb5c"
};
export type Device = {
    iddispositivo: number,
    nombredispositivo: string,
    url: string,
    estadodispositivo: number,
    fecfin: string,
    fecini: string,
    idusuario: string,
    nombreusuario: string,
    residencial: string,
};

export default class Firebase {
    db: firebase.database.Database;
    auth: firebase.auth.Auth;

    @observable
    isLoading = true;
    @observable
    user: Nullable<firebase.User> = null;
    @observable
    userData: any;
    private client?: ClientJS

    constructor() {
        app.initializeApp(firebaseConfig);
        this.db = app.database();
        this.auth = app.auth();
        this.auth.onAuthStateChanged(this.updateUser)
    }

    get Client() {
        if (!this.client)
            this.client = new ClientJS();

        return this.client
    }

    async getUserDevices(): Promise<Array<Device>> {
        let fbuser = this?.auth?.currentUser as any as firebase.User;
        let userData = await this.updateUser(fbuser);

        if (!userData || userData.estado)
            return [];
        let resp = await Axios.post(`${API_URL}/getUserDevices`, {
            id: fbuser.uid
        });
        return resp.data[0];
    }

    async useDevice(device: Device) {
        if (!this.isLoggedIn) return [];
        let userData = this.userData;
        if (!userData || userData.estado)
            return [];
        console.log("device", device)
        // @ts-ignore
        window.client = this.Client;
        let client = this.Client;
        let data = {
            // @ts-ignore
            ua: client.getUA(),
            browser: client.getBrowser(),
            engine: client.getEngine(),
            os: client.getOS(),
            device: client.getDevice(),
            cpu: client.getCPU()
        };
        await Axios.post(`${API_URL}/actualizaUsoUsuarios`, {
            id: this.user!.uid, devID: device.iddispositivo, nota: JSON.stringify(data, undefined, "\t")
        });
        let url = device.url.includes("http") ? device.url : `http://miriad.duckdns.org:8123/api/webhook/${device.url}`;
        Axios.get(url, {
            data: {
                id: this.user!.uid
            }, params: {
                id: this.user!.uid
            }
        }).then((resp) => {
            console.log("resp", resp);
        }).catch(err => {
            console.log("error", err);
        });
    }

    @computed
    get isLoggedIn() {
        return !!this.user;
    }

    logIn = (email: string, password: string) => {
        return this.auth.signInWithEmailAndPassword(email.trim(), password)
    };

    logOut = () => {
        return this.auth.signOut();
    }

    register = (email: string, password: string) => {
        return this.auth.createUserWithEmailAndPassword(email.trim(), password).then((value => {
            return value;
        }))
    }

    forgotPassword = (email: string) => {
        return this.auth.sendPasswordResetEmail(email.trim())

    }

    @action
    private updateUser = async (authData: Nullable<firebase.User>) => {
        this.user = authData;
        this.isLoading = false;
        if (!authData)
            return;
        return Axios.post(`${API_URL}/updateUserStatus`, {
            id: authData.uid,
            nombre: authData.displayName || authData.email
        }).then((resp) => {
            this.userData = resp.data[0][0];
            return resp.data[0][0];
        });

    };
}

export type Nullable<T> = T | undefined | null;
const FirebaseContext = React.createContext(undefined) as any as React.Context<Firebase>;
export {FirebaseContext};
