import React, {useState} from 'react';
import {
    IonButton,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import {FirebaseContext} from "../services/Firebase";
import {useHistory} from "react-router";
import NotificationContext from "../services/NotificationService";

const columnas = {
    user: "user",
    pass: "pass",
};
const Tab1: React.FC = () => {
    const firebase = React.useContext(FirebaseContext);
    const notification = React.useContext(NotificationContext);

    const router = useHistory();
    const [data, setData] = useState({
        [columnas.user]: "",
        [columnas.pass]: ""
    });
    const validateAll = React.useCallback(() => {
        if (!data[columnas.user]) {
            notification.addNotification("es necesario introducir el email", "error", {
                buttons: ["OK"]
            });
            return false;
        }
        if (!data[columnas.pass]) {
            notification.addNotification("es necesario introducir la contrasena", "error", {
                buttons: ["OK"]
            });
            return false;
        }
        return true;
    }, [data, notification]);

    const onChange = React.useCallback((campo) => (event: CustomEvent) => {
        setData(data => ({...data, [campo]: event.detail.value}));
    }, []);
    const onLogin = React.useCallback(() => {
        if (!validateAll())
            return;
        firebase.logIn(data[columnas.user], data[columnas.pass]).catch((reason => {
            notification.addNotification(JSON.stringify(reason), "error", {
                buttons: ["OK"]
            });
        }))
    }, [data, firebase, notification, validateAll]);
    const onRegister = React.useCallback(() => {
        if (!validateAll())
            return;
        firebase.register(data[columnas.user], data[columnas.pass]).then(() => {
            notification.addNotification("El usuario a sido creado satisfactoriamente", "", {
                buttons: ["OK"]
            });
        }).catch((reason => {
            if (reason.code === "auth/email-already-in-use") {
                notification.addNotification("el nombre de usuario ingresado ya esta en uso. Si no recuerda la clave utilizar el boton \"Clave Olvidada\"", "error", {
                    buttons: ["OK"]
                });
            } else {
                notification.addNotification(JSON.stringify(reason), "error", {
                    buttons: ["OK"]
                });
            }
        }))
    }, [data, firebase, notification, validateAll]);
    const onForgotPassword = React.useCallback(() => {
        if (!data[columnas.user]) {
            return notification.addNotification("es necesario introducir el email", "error", {
                buttons: ["OK"]
            });
        }
        firebase.forgotPassword(data[columnas.user]).then(() => {
            notification.addNotification("se le ha enviado un email con las intrucciones para recuperar la contrasena", "Contrasena olvidada", {
                buttons: ["OK"]
            });
        })
    }, [data, firebase, notification]);
    React.useEffect(() => {
        const unsubcribe = firebase?.auth?.onAuthStateChanged((user) => {
            if (user) {
                router.push("/app/dispositivos");
            }
            unsubcribe?.();
        });
        // eslint-disable-next-line
    }, []);
    if (firebase.isLoading) {
        return <></>;
    }

    // @ts-ignore
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Login</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Login</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div className="container">
                    <IonItem>
                        <IonLabel position="floating">Username</IonLabel>
                        <IonInput value={data[columnas.user]} onIonChange={onChange(columnas.user)}/>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="floating">Password</IonLabel>
                        <IonInput type={"password"} value={data[columnas.pass]} onIonChange={onChange(columnas.pass)}/>
                    </IonItem>
                    <IonButton onClick={onLogin}>Login</IonButton>
                    <IonButton onClick={onRegister}>Registrar</IonButton>
                    <IonButton onClick={onForgotPassword}>Clave Olvidada</IonButton>
                </div>

            </IonContent>
        </IonPage>
    );
};

export default Tab1;
